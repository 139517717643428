(function ($) {
    let $fn = $(".comp_contact");

    if ($fn.length) {
        let $map = $fn.find("[data-map]");

        if($map.length) {
            $.getScript("https://maps.googleapis.com/maps/api/js?key=AIzaSyA7jgoZaPovpl5xSGHOU_qbfAS81KQHahY") // AIzaSyAIz5l_UM1ahT1yFxPQD6-c7U9OyXAkEdY
                .done(function () {
                    $map.nl_google_map('init');
                    $map.nl_google_map('markers_add');
                });
        }
    }
})(jQuery);