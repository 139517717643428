(function ($) {
    let $fn = $(".comp_gallery");

    if ($fn.length) {
        $fn.on("click","[data-gallery]",function () {
            let $this = $(this),
                $data = $this.data("gallery");

            $this.lightGallery({
                dynamic: true,
                thumbnails: true,
                counter: true,
                download: true,
                closable: true,
                zoom: false,
                dynamicEl: $data,
            })
        });
    }
})(jQuery);