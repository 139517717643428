function fn_render_gallery_slider($section) {
    let $slider = $section.find("[data-slider]"),
        $slider_nav = $section.find("[data-slider-nav]"),
        $slider_count = $slider.children().length,
        $counter = $section.find("[data-slider-counter]");

    if($slider_count > 1){
        $slider.slick({
            slidesToShow: 1,
            arrows: true,
            dots: false,
            prevArrow: "<i class=\"icon icon--arrow-big-left slick-prev\"></i>",
            nextArrow: "<i class=\"icon icon--arrow-big-right slick-next\"></i>",
            asNavFor: $slider_nav,
            responsive: [
                {
                    breakpoint: 768,
                    settings: {
                        prevArrow: "<i class=\"icon icon--arrow-left slick-prev\"></i>",
                        nextArrow: "<i class=\"icon icon--arrow-right slick-next\"></i>",
                    }
                }
            ]
        });

        $slider_nav.slick({
            slidesToShow: 9,
            arrows: false,
            dots: false,
            asNavFor: $slider,
            centerMode: $slider_count >= 10,
            centerPadding: 0,
            swipeToSlide: true,
            focusOnSelect: true,
        });

        $slider.on('afterChange', function(event, slick, currentSlide){
            $counter.text(currentSlide+1);
        });
    }
};