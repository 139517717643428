$.fn.nl_google_map = function(event,data) {
    return this.each(function() {
        var $this = $(this);
        if (!$this.length) {
            return false;
        }
        if (event === 'init') {
            nl_google_map_init();
        }
        else if (event === 'markers_add') {
            nl_google_map_markers_add_method(data);
        }

        else if (event === 'markers_remove') {
            nl_google_map_markers_remove_method();
        }

        else if (event === 'markers_reload') {
            nl_google_map_markers_remove_method();
            nl_google_map_markers_add_method();
        }

        else if (event === 'position') {
            nl_google_map_position_method(data);
        }

        else if (event === 'marker_click') {
            nl_google_map_marker_click_method(data);
        }

        function nl_google_map_init() {
            var $zoom = $this.data("zoom"),
                $coords = $this.data('coords').split(/[\s,]+/),
                $markers = $this.data("markers"),
                $markers_temp = [],
                $map_options = {
                    zoom: $zoom,
                    center: {
                        lat: parseFloat($coords[0]), lng: parseFloat($coords[1])
                    },
                    mapTypeControl: false,
                    styles: [
                        {
                            "elementType": "geometry",
                            "stylers": [
                                {
                                    "color": "#f5f5f5"
                                }
                            ]
                        },
                        {
                            "elementType": "labels.icon",
                            "stylers": [
                                {
                                    "visibility": "on"
                                }
                            ]
                        },
                        {
                            "elementType": "labels.text.fill",
                            "stylers": [
                                {
                                    "color": "#616161"
                                }
                            ]
                        },
                        {
                            "elementType": "labels.text.stroke",
                            "stylers": [
                                {
                                    "color": "#f5f5f5"
                                }
                            ]
                        },
                        {
                            "featureType": "administrative.land_parcel",
                            "elementType": "labels.text.fill",
                            "stylers": [
                                {
                                    "color": "#bdbdbd"
                                }
                            ]
                        },
                        {
                            "featureType": "poi",
                            "elementType": "geometry",
                            "stylers": [
                                {
                                    "color": "#eeeeee"
                                }
                            ]
                        },
                        {
                            "featureType": "poi",
                            "elementType": "labels.text.fill",
                            "stylers": [
                                {
                                    "color": "#757575"
                                }
                            ]
                        },
                        {
                            "featureType": "poi.park",
                            "elementType": "geometry",
                            "stylers": [
                                {
                                    "color": "#e5e5e5"
                                }
                            ]
                        },
                        {
                            "featureType": "poi.park",
                            "elementType": "labels.text.fill",
                            "stylers": [
                                {
                                    "color": "#9e9e9e"
                                }
                            ]
                        },
                        {
                            "featureType": "road",
                            "elementType": "geometry",
                            "stylers": [
                                {
                                    "color": "#ffffff"
                                }
                            ]
                        },
                        {
                            "featureType": "road.arterial",
                            "elementType": "labels.text.fill",
                            "stylers": [
                                {
                                    "color": "#757575"
                                }
                            ]
                        },
                        {
                            "featureType": "road.highway",
                            "elementType": "geometry",
                            "stylers": [
                                {
                                    "color": "#dadada"
                                }
                            ]
                        },
                        {
                            "featureType": "road.highway",
                            "elementType": "labels.text.fill",
                            "stylers": [
                                {
                                    "color": "#616161"
                                }
                            ]
                        },
                        {
                            "featureType": "road.local",
                            "elementType": "labels.text.fill",
                            "stylers": [
                                {
                                    "color": "#9e9e9e"
                                }
                            ]
                        },
                        {
                            "featureType": "transit.line",
                            "elementType": "geometry",
                            "stylers": [
                                {
                                    "color": "#e5e5e5"
                                }
                            ]
                        },
                        {
                            "featureType": "transit.station",
                            "elementType": "geometry",
                            "stylers": [
                                {
                                    "color": "#eeeeee"
                                }
                            ]
                        },
                        {
                            "featureType": "water",
                            "elementType": "geometry",
                            "stylers": [
                                {
                                    "color": "#c9c9c9"
                                }
                            ]
                        },
                        {
                            "featureType": "water",
                            "elementType": "labels.text.fill",
                            "stylers": [
                                {
                                    "color": "#9e9e9e"
                                }
                            ]
                        }
                    ]
                };

            var $map = new google.maps.Map($this[0], $map_options);

            function nl_google_map_position(data) {
                var $location = data.split(/[\s,]+/);
                var latLng = new google.maps.LatLng($location[0],$location[1]);
                $map.panTo(latLng);
                $map.setZoom(17);
            }
            function nl_google_map_markers_add(callback) {
                var $bounds = new google.maps.LatLngBounds();
                var $infoWindow = new google.maps.InfoWindow({
                    content: "",
                    maxWidth: 190
                });

                if ($this.filter("[data-marker]").length) {
                    var $main_marker_position = new google.maps.LatLng(parseFloat($coords[0]), parseFloat($coords[1]));
                    var $main_marker_image = {
                        url: $this.data("marker"),
                        anchor: new google.maps.Point(60, 90),
                        scaledSize: new google.maps.Size(120, 90)
                    };

                    var $main_marker = new google.maps.Marker({
                        position: $main_marker_position,
                        map: $map,
                        icon: $main_marker_image,
                        zIndex: 9999
                    });

                    $markers_temp.push($main_marker);
                    $bounds.extend($main_marker_position);

                    if($this.data("link") !== undefined) {
                        google.maps.event.addListener($main_marker, 'click', function(){
                            window.location.href = $this.data("link");
                        });
                    }
                }
                if ($this.filter("[data-markers]").length) {
                    $markers = $this.data("markers");
                    for (var i = 0; i < $markers.length; i++) {
                        var $marker = $markers[i],
                            $position = new google.maps.LatLng($marker.lat, $marker.lng),
                            $image = {
                                url: $marker.icon,
                                scaledSize: new google.maps.Size(58, 75),
                                origin: new google.maps.Point(0, 0),
                                anchor: new google.maps.Point(30, 70)
                            },
                            $icon = new google.maps.Marker({
                                position: $position,
                                map: $map,
                                icon: $image,
                                title: $marker.name,
                                data: $marker.data
                            });

                        $markers_temp.push($icon);
                        $bounds.extend($position);

                        google.maps.event.addListener($infoWindow, 'domready', function() {
                            $('.part_ui_info').closest('.gm-style-iw').parent().addClass('gm-custom-iw');
                        });

                        if (callback) {
                            callback($icon, $markers_temp, $map, $infoWindow);
                        }
                    }

                    google.maps.event.addListenerOnce($map, 'bounds_changed', function() {
                        if(this.getZoom() > $zoom) {
                            this.setZoom($zoom);
                        }
                    });

                    $map.fitBounds($bounds);
                }

                function nl_google_map_markers_remove() {
                    for (var i = 0; i < $markers_temp.length; i++) {
                        $markers_temp[i].setMap(null);
                    }

                    $markers_temp = [];
                }
                window.nl_google_map_markers_remove_method = nl_google_map_markers_remove;
            }
            function nl_google_map_marker_click(index) {
                google.maps.event.trigger($markers_temp[index], 'click');
            }

            window.nl_google_map_markers_add_method = nl_google_map_markers_add;
            window.nl_google_map_position_method = nl_google_map_position;
            window.nl_google_map_marker_click_method = nl_google_map_marker_click;
        }
    });
};